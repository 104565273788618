<template>
  <div class="Omeprazol">
    <div class="game-stage1" v-show="!result.win && !result.lose">
      <div class="game-bar">
        Завдання: зіграйте з парієтальними клітинами у гру! Зниження рівня
        кислотності проти HCl
      </div>

      <div class="game">
        <div class="game__box">
          <div class="game__box--content">
            <table class="game__box--wrap">
              <tr v-for="i in 3" :key="i">
                <td v-for="j in 3" :key="j" class="game__box--cell">
                  <div
                      @click="
                      !cells[3 * (i - 1) + j] ? makeMove(3 * (i - 1) + j) : null
                    "
                      :class="{
                      blue: cells[3 * (i - 1) + j] === 'x',
                      lime: cells[3 * (i - 1) + j] === 'o',
                    }"
                  >
                    <template v-if="cells[3 * (i - 1) + j]">
                      {{ cells[3 * (i - 1) + j] === "x" ? "O" : "HCI" }}
                    </template>
                  </div>
                </td>
              </tr>
            </table>

            <div v-if="gameWinCombination" class="game__box--win" :class="'w' + gameWinCombination"></div>
          </div>
        </div>
      </div>

      <div class="tip">
        знайомі всім хрестики-нулики, де ваш хід буде позначено як О, а хід
        противника – HCl
      </div>
    </div>

    <div class="win" v-show="result.win">
      <div class="win__green-block green stage1">
        <p class="win__text  green">
          <span>Омепразол-Тева!</span>
          <br/>
          Діюча речовина препарату&nbsp;— омепразол, як написав лікар у рецепті
        </p>

        <div class="win__arrow-down green"></div>
      </div>

      <img
          class="win__img-circle"
          src="../assets/img/game-omeprazol/win.png"
          alt=""
      />

      <div class="win__text-center">
        Чудово! Завдяки Вам ми досягли необхідного рівня кислотності! Гарна
        рекомендація та неабияка уважність
      </div>

      <div class="win__info-text-btn">
        <div class="win__info-text">
          <p>
            <span>Омепразол-Тева </span>– для лікування пептичної виразки і
            гастроезофагеальної рефлюксної хвороби. Інгібітори протонної
            помпи*<sup>1</sup>
          </p>
        </div>
        <div class="win__info-btn" @click="winPopup = !winPopup">
          <svg
              width="46"
              height="46"
              viewBox="0 0 46 46"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="23" cy="23" r="23" fill="#35786E"/>
            <path
                d="M21 14.0109C21 12.6703 21.6667 12 23 12C24.3333 12 25 12.6703 25 14.0109C25 14.6497 24.8313 15.1491 24.494 15.509C24.1647 15.8599 23.6667 16.0353 23 16.0353C21.6667 16.0353 21 15.3605 21 14.0109ZM24.8313 33H21.1566V17.9113H24.8313V33Z"
                fill="white"
            />
          </svg>
        </div>
      </div>

      <div class="win__popup" v-show="winPopup">
        <div class="win__popup--close-btn" @click="winPopup = !winPopup">
          <svg
              width="26"
              height="26"
              viewBox="0 0 26 26"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="13" cy="13" r="12.5" stroke="#00A03B"/>
            <path d="M19.3643 6.63599L6.63634 19.3639" stroke="#00A03B"/>
            <path d="M19.3643 19.3639L6.63634 6.63597" stroke="#00A03B"/>
          </svg>
        </div>

        <div class="win__popup--text">
          *З повним переліком показань детальніше можна ознайомитись в
          інструкції для медичного застосування лікарського засобу.
        </div>
        <div class="win__popup--text">
          1. Інструкція для медичного застосування лікарського засобу
          ОМЕПРАЗОЛ-ТЕВА, капсули гастрорезистентні тверді по 40мг. РП
          №UA/15152/01/01. Наказ МОЗ України №1725 від 11.08.2021.
        </div>
        <div class="win__popup--text">
          Інформація про лікарський засіб. РП №UA/15152/01/01, №UA/15152/01/02.
          Для професійної діяльності медичних та фармацевтичних працівників.
        </div>
        <div class="win__popup--text">
          Омепразол-Тева (1 капсула містить омепразолу 20мг або 40мг у складі
          гастрорезистентних капсул). Характеристики: капсули гастрорезистентні
          тверді. 20мг: синьо-помаранчеві капсули №2 зі штампом «О» на кришечці
          і «20» на корпусі, що містять мікрогранули біло-бежевого кольору.
          40мг: синьо-помаранчеві капсули №0 зі штампом «О» на кришечці і «40»
          на корпусі, що містять мікрогранули біло-бежевого кольору. Лікувальні
          властивості: засіб для лікування пептичної виразки і
          гастроезофагеальної рефлюксної хвороби. Інгібітор протонної помпи.
          Найбільш частими побічними реакціями є: головний біль, абдомінальний
          біль, запор, діарея, метеоризм та нудота/блювання.
        </div>
        <div class="win__popup--text">
          Контакти: ТОВ «Тева Україна», 02152, м. Київ, просп. П. Тичини, 1-В,
          поверх 9; 0-800-502-284 CBG-UA-00826. Термін дії матеріалу –
          23.08.2024.
        </div>
      </div>
    </div>

    <div class="lose" v-show="result.lose">
      <div class="lose__red-block stage1">
        <p class="lose__text desk white">
          <span>Все ще дуже кисло!</span>
          <br/>
          Спробуйте ще раз. Не дайте HCl виділитись у шлунковий сік!
        </p>

        <div class="lose__arrow-down"></div>
      </div>

      <img
          class="lose__img-circle"
          src="../assets/img/game-atorvastatin/lose.png"
          alt=""
      />

      <div class="lose__repeat hover_type6" @click="reset()">
        <p>
          Ще раз!
        </p>
      </div>
    </div>

    <div v-show="result.lose" class="notes-wrapper">
      <span>
        Інформація про лікарський засіб. РП №UA/15152/01/01, №UA/15152/01/02. Для професійної діяльності медичних та фармацевтичних працівників.</span>

      <span>Омепразол-Тева (1 капсула містить омепразолу 20 мг або 40 мг у складі гастрорезистентних капсул). Характеристики: капсули гастрорезистентні тверді. 20 мг: синьо-помаранчеві капсули №2 зі штампом «О» на кришечці і «20» на корпусі, що містять мікрогранули біло-бежевого кольору. 40 мг: синьо-помаранчеві капсули №0 зі штампом «О» на кришечці і «40» на корпусі, що містять мікрогранули біло-бежевого кольору. Лікувальні властивості: засіб для лікування пептичної виразки і гастроезофагеальної рефлюксної хвороби. Інгібітор протонної помпи. Найбільш частими побічними реакціями є: головний біль, абдомінальний біль, запор, діарея, метеоризм та нудота/блювання.
      </span>
      <span>Контакти: ТОВ «Тева Україна», 02152, м. Київ, просп. П. Тичини, 1-В, поверх 9; 0-800-502-284</span>

      <span>CBG-UA-00826. Термін дії матеріалу – 23.08.2024.</span>
    </div>

    <div v-show="!result.win && !result.lose" class="notes-wrapper desk">
           <span>
        Інформація про лікарський засіб. РП №UA/15152/01/01, №UA/15152/01/02. Для професійної діяльності медичних та фармацевтичних працівників.</span>

      <span>Омепразол-Тева (1 капсула містить омепразолу 20 мг або 40 мг у складі гастрорезистентних капсул). Характеристики: капсули гастрорезистентні тверді. 20 мг: синьо-помаранчеві капсули №2 зі штампом «О» на кришечці і «20» на корпусі, що містять мікрогранули біло-бежевого кольору. 40 мг: синьо-помаранчеві капсули №0 зі штампом «О» на кришечці і «40» на корпусі, що містять мікрогранули біло-бежевого кольору. Лікувальні властивості: засіб для лікування пептичної виразки і гастроезофагеальної рефлюксної хвороби. Інгібітор протонної помпи. Найбільш частими побічними реакціями є: головний біль, абдомінальний біль, запор, діарея, метеоризм та нудота/блювання.
      </span>
      <span>Контакти: ТОВ «Тева Україна», 02152, м. Київ, просп. П. Тичини, 1-В, поверх 9; 0-800-502-284</span>

      <span>CBG-UA-00826. Термін дії матеріалу – 23.08.2024.</span>
    </div>
  </div>
</template>

<script>
import Timer from "./Timer.vue";

export default {
  components: {
    timer: Timer,
  },

  data: () => ({
    time: 30,
    timer: null,
    winPopup: false,

    result: {
      win: false,
      lose: false,
    },

    gameStatus: "turn",
    gameWinCombination: null,
    activeMark: "x",
    cells: {
      1: "",
      2: "",
      3: "",
      4: "",
      5: "",
      6: "",
      7: "",
      8: "",
      9: "",
    },
    winCombinations: [
      [1, 2, 3],
      [4, 5, 6],
      [7, 8, 9], // rows
      [1, 4, 7],
      [2, 5, 8],
      [3, 6, 9], // columns
      [1, 5, 9],
      [3, 5, 7], // diagonals
    ],
  }),

  computed: {
    cssVars() {
      return {
        "--width": this.widthBox,
      };
    },
    heightContent() {
      return {
        "--height": this.gameHeight,
      };
    },
    prettyTime() {
      let time = this.time / 60;
      let minutes = parseInt(time);
      let secondes = Math.round((time - minutes) * 60);
      return minutes + ":" + secondes;
    },
  },

  methods: {
    start() {
      if (!this.timer) {
        this.timer = setInterval(() => {
          if (this.time > 0) {
            this.time--;
          } else {
            clearInterval(this.timer);
          }
        }, 1000);
      }
    },

    reset() {
      this.time = 30;
      this.result.lose = false;
      this.result.win = false;

      Object.keys(this.cells).forEach((i) => {
        this.cells[i] = "";
      });
      this.activeMark = "x";
      this.gameWinCombination = null;

      window.scrollTo(0, 0)
      this.start();
    },

    getOtherSign(sign) {
      return sign === "x" ? "o" : "x";
    },

    move() {
      // Алгоритм хода бота
      let ready = false;
      let cell = null;

      // cells
      let cells = this.cells;
      let bot_cells = [];
      let opponent_cells = [],
          empty_cells = [];

      // signs
      let bot_sign = "o";
      let opponent_sign = this.getOtherSign(bot_sign);

      // win combinations
      let win_combinations = this.winCombinations;
      let possible_bot_combinations = [];
      let possible_opponent_combinations = [];

      // Пробежка по полю, получаем ячейки свои и соперника
      Object.keys(cells).forEach(function (key) {
        if (cells[key] === bot_sign) {
          bot_cells.push(Number(key));
        } else if (cells[key] === opponent_sign) {
          opponent_cells.push(Number(key));
        } else {
          empty_cells.push(Number(key));
        }
      });

      win_combinations.forEach(function (combination) {
        let isfit = true;
        for (let key in combination) {
          if (cells[combination[key]] === opponent_sign) {
            isfit = false;
            break;
          }
        }
        if (isfit) {
          possible_bot_combinations.push(combination);
        }
      });

      if (bot_cells.length > 0) {
        // 1. Проверяем свою победную комбинаю (две клетки подряд).
        //    Если есть, то завершаем игру
        possible_bot_combinations.every(function (combination) {
          let counter = 0,
              emptyCell = null;
          for (let key in combination) {
            if (cells[combination[key]] === bot_sign) {
              counter++;
            }
            if (!cells[combination[key]].length) {
              emptyCell = combination[key];
            }
          }
          if (counter > 1) {
            // Выигрышная комбинация
            cell = emptyCell;
            ready = true;
            return false;
          }
          return true;
        });
      }

      if (!ready) {
        // 2. Проверяем победную комбинацию соперника
        //    Если есть, то не занимаем последнюю ячейку, не даем победить
        win_combinations.forEach(function (combination) {
          let isfit = true;
          for (let key in combination) {
            if (cells[combination[key]] === bot_sign) {
              isfit = false;
              break;
            }
          }
          if (isfit) {
            possible_opponent_combinations.push(combination);
          }
        });

        possible_opponent_combinations.every(function (combination) {
          let counter = 0,
              emptyCell = null;
          for (let key in combination) {
            if (cells[combination[key]] === opponent_sign) {
              counter++;
            }
            if (!cells[combination[key]].length) {
              emptyCell = combination[key];
            }
          }
          if (counter > 1) {
            // Выигрышная комбинация соперника
            cell = emptyCell;
            ready = true;
            return false;
          }
          return true;
        });
      }

      // 3. Строим линию
      if (!ready) {
        // проверяем центр
        if (!cells[5].length) {
          cell = 5;
          ready = true;
        }
      }

      if (!ready) {
        // Если есть шанс выиграть
        if (possible_bot_combinations.length) {
          // выбираем рандомную комбинацию
          let rand = Math.floor(
              Math.random() * possible_bot_combinations.length
          );
          let comb = possible_bot_combinations[rand];
          let cll = null;
          // пробуем 1, 3, 7, 9
          comb.every(function (cell) {
            if (cell % 2 && !cells[cell].length) {
              cll = cell;
              return false;
            } else if (!cells[cell].length) {
              cll = cell;
              return false;
            }
            return true;
          });
          cell = cll;
        } else {
          // Нет шанса выиграть, играем рандом до ничьи
          let rand = Math.floor(Math.random() * empty_cells.length);
          cell = empty_cells[rand];
        }
      }

      // Имитируем клик в ячейку
      cell ? this.makeMove(cell) : null;
    },

    isWin: function (lastCellOrder) {
      let isWin = false,
          currentSign = this.cells[lastCellOrder],
          currentCombinations = [];
      // Собираем выигрышные комбинации для текущей ячейки
      this.winCombinations.forEach(function (combination) {
        for (let key in combination) {
          if (combination[key] === lastCellOrder) {
            currentCombinations.push(combination);
            break;
          }
        }
      });

      // Проверяем каждую комбинацию на выигрыш
      currentCombinations.every((combination) => {
        let counter = 0;
        for (let key in combination) {
          if (this.cells[combination[key]] === currentSign) {
            counter++;
          }
        }
        // Если комбинация выигрышная
        if (counter === 3) {
          // Найдена выигрышная комбинация
          this.gameWinCombination = combination.join("");
          isWin = true;
          return false;
        }
        return true;
      });
      return isWin;
    },

    isGridFull: function () {
      let isFull = true;
      for (let key in this.cells) {
        if (!this.cells[key].length) {
          isFull = false;
          break;
        }
      }
      return isFull;
    },

    checkGameStatus: function (lastCellOrder) {
      if (this.isWin(lastCellOrder)) {
        // Победа
        this.gameStatus = "win";

      } else {
        if (this.isGridFull()) {
          // Ничья
          this.gameStatus = "draw";
        } else {
          // Победы нет, игра продолжается
          this.gameStatus = "turn";
        }
      }
    },

    makeMove: function (cellOrder) {
      if (this.gameWinCombination) return;

      // Заполняем ячейку на поле
      this.cells[cellOrder] = this.activeMark;

      // Проверяем выигрышные комбинации
      this.checkGameStatus(cellOrder);


      switch (this.gameStatus) {
        case "turn":
          // Меняем активного игрока
          this.activeMark = this.getOtherSign(this.activeMark);

          // Выполняем ход бота
          if (this.activeMark === "o") {
            this.move();
          }
          break;
        case "draw":
          this.onResult("win");
          break;
        case "win":
          switch (this.activeMark) {
            case "o":
              this.onResult("lose");
              break;
            case "x":
              this.onResult("win");
              break;
            default:
              break;
          }
          break;
        default:
          break;
      }


    },

    onResult(result) {
      setTimeout(() => {
        this.result[result] = true;

        window.scrollTo(0, 0)

      }, 1000);
    },
  },

  mounted() {
    this.start();
  },
};
</script>

<style scoped lang="scss">
@import "../assets/style/fonts.css";
@import "../assets/style/vars";

.Omeprazol {
  max-width: 1240px;
  width: 100%;

  display: flex;
  flex-direction: column;

  padding-top: 40px;

  min-height: calc((var(--vh, 1vh) * 100) - (var(--height_header)));
  height: 100%;
  margin: 0 auto;

  .timer {
    font-family: $B;
  }

  .game-bar {
    max-width: 610px;
    padding: 0 20px;
    margin: 0 auto;

    font-family: $B;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;

    display: flex;
    align-items: center;
    text-align: center;

    color: #00a03b;
  }

  .game__box {
    margin: 30px auto;
    padding: 20px;
    background: #00a03b;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.4);
    border-radius: 20px;

    width: calc(min(400px, 100vw - 40px));
    height: calc(min(400px, 100vw - 40px));

    &--content {
      position: relative;
      width: 100%;
      height: 100%;
    }

    &--wrap {
      width: 100%;
      height: 100%;

      border-collapse: collapse;
      border: 4px solid #fff;
      border-radius: 4px;
    }

    &--cell {
      width: 33.333%;
      height: 33.333%;
      border: 4px solid #fff;

      vertical-align: middle;
      text-align: center;


      & > div {
        cursor: pointer;
        width: 100%;
        height: 100%;

        display: flex;
        align-items: center;
        justify-content: center;

        font-size: 38px;
        line-height: 20px;
        color: #ffffff;

        &.blue {
          background-color: $blue;
        }

        &.lime {
          background-color: $lime;
        }
      }
    }

    &--win {
      $w: 6px;

      position: absolute;
      background-color: #ffffff;
      border: $w solid #fff;
      border-radius: 8px;

      &.w123,
      &.w456,
      &.w789 {
        left: calc(100% / 6 - #{$w} - 10%);
        right: calc(100% / 6 - #{$w} - 10%);
      }

      &.w123 {
        top: calc(100% / 6 - #{$w});
      }

      &.w456 {
        top: calc((100% / 6) * 3 - #{$w});
      }

      &.w789 {
        top: calc((100% / 6) * 5 - #{$w});
      }

      &.w147,
      &.w258,
      &.w369 {
        top: calc(100% / 6 - 10%);
        bottom: calc(100% / 6 - 10%);
      }

      &.w147 {
        left: calc(100% / 6 - #{$w});
      }

      &.w258 {
        left: calc((100% / 6) * 3 - #{$w});
      }

      &.w369 {
        left: calc((100% / 6) * 5 - #{$w});
      }

      &.w159,
      &.w357 {
        top: -10%;
        left: calc((100% / 6) * 3 - #{$w});
        bottom: -10%;
      }

      &.w159 {
        transform: rotate(-45deg);
      }

      &.w357 {
        transform: rotate(45deg);
      }
    }
  }

  .tip {
    margin: auto;
    max-width: 415px;

    font-family: $M;
    font-weight: 500;
    font-size: 16px;
    line-height: 120%;

    display: flex;
    align-items: center;
    text-align: center;
    color: #424242;

    padding: 0 20px;
    opacity: 0.4;
  }

  .lose {
    position: relative;
    display: flex;
    flex-direction: column;
    max-width: 650px;
    width: 100%;

    padding: 0 20px 30px 20px;

    margin: 0 auto;

    &__red-block {
      background: #c40000;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      max-width: calc(100% - 40px);
      width: 100%;
      margin: 0 auto;
      padding: 25px 15px;
      box-shadow: 0 0 20px rgba(0, 0, 0, 0.4);
      border-radius: 14px;

      span {
        font-family: $EB;
      }
    }

    &__text {
      font-size: 20px;
      line-height: 120%;
      font-family: $M;
      color: white;
      text-align: center;
    }

    &__arrow-down {
      position: absolute;
      bottom: -9px;
      width: 0;
      left: 0px;
      right: 0;
      margin: 0 auto;
      height: 0;
      border-left: 24px solid transparent;
      border-right: 24px solid transparent;

      border-top: 10px solid #c40000;
    }

    &__img-circle {
      max-width: 358px;
      width: 100%;
      height: auto;
      display: block;
      margin: 6% auto 0 auto;
      border-radius: 50%;
    }

    &__repeat {
      margin: -3% auto 0 auto;
      border: 2px solid $green;
      border-radius: 35px;
      padding: 14px 15px;
      background: white;

      max-width: 230px;
      width: 100%;

      cursor: pointer;

      p {
        color: $green;

        font-family: $EB;
        font-size: 16px;
        line-height: 22px;
        text-align: center;
      }
    }
  }

  .win {
    min-height: calc((var(--vh, 1vh) * 100) - (var(--height_header)) - 40px);

    position: relative;
    padding: 0 20px 0 20px;

    max-width: 650px;
    height: 100%;
    width: 100%;
    margin: 0 auto 0 auto;

    display: flex;
    flex-direction: column;

    &__green-block {
      background: $green;
      position: absolute;
      left: 0;
      right: 0;
      max-width: calc(100% - 40px);
      width: 100%;
      margin: 0 auto;
      padding: 25px 15px;
      box-shadow: 0 0 20px rgba(0, 0, 0, 0.4);
      border-radius: 14px;
      height: 110px;
    }

    &__text {
      font-size: 20px;
      line-height: 120%;
      font-family: $M;
      color: white;
      text-align: center;

      &.mob {
        display: none;
      }

      span {
        font-family: $EB;
      }
    }

    &__arrow-down {
      position: absolute;
      bottom: -9px;
      width: 0;
      left: 0px;
      right: 0;
      margin: 0 auto;
      height: 0;
      border-left: 24px solid transparent;
      border-right: 24px solid transparent;

      border-top: 10px solid $green;

      &.blue {
        border-top: 10px solid $blue;
      }
    }

    &__img-circle {
      max-width: 309px;
      width: 100%;
      height: auto;
      display: block;
      margin: 40px auto 0 auto;

      box-shadow: 0 4px 20px rgba(0, 0, 0, 0.4);
      border-radius: 50%;

    }

    &__text-center {
      text-align: center;
      margin-top: 40px;
      margin-bottom: 20px;
      color: $green;
      font-size: 20px;
      line-height: 120%;
      font-family: $B;
    }

    &__info-text-btn {
      margin: auto 0 40px 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &__info-text {
      max-width: 474px;
      width: 100%;
      margin-right: 15px;

      p {
        font-size: 16px;
        line-height: 120%;
        color: $grey;
        font-family: $M;
        vertical-align: center;
        position: relative;

        sup {
          font-size: 80%;
          line-height: 0;
          vertical-align: top;
        }
      }

      span {
        font-family: $EB;
      }
    }

    &__info-btn {
      margin-left: 15px;
      cursor: pointer;
    }

    &__popup {
      bottom: 40px;
      position: absolute;
      width: calc(100% - 20px);
      background: white;
      padding: 20px 20px 40px 20px;
      left: 50%;
      transform: translateX(-50%);

      box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    }

    &__popup--close-btn {
      display: flex;
      justify-content: flex-end;
      margin-bottom: 24px;

      svg {
        display: block;
        cursor: pointer;
      }
    }

    &__popup--text {
      font-size: 10px;
      line-height: 120%;
      font-family: $M;
      color: $grey;
      margin-bottom: 2px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .notes-wrapper {
    max-width: 1270px;
    width: 100%;
    margin: auto auto 0 auto;

    padding: 16px 20px;

    font-size: 8px;
    line-height: 8px;
    font-family: $M;
    color: $grey;

    span {
      display: block;
      margin-bottom: 2px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    &.active {
      color: white;
    }
  }

  @media screen and (max-width: 600px) {
    padding-top: 20px;

    .tip {
      font-size: 14px;
    }

    .lose {
      padding-bottom: 0;

      &__red-block {
        font-size: 16px;
        line-height: 120%;
        padding: 15px;

        position: relative;
        top: 0;
      }

      &__text {
        font-size: 16px;
        line-height: 120%;
      }

      &__arrow-down {
      }

      &__img-circle {
        max-width: 100%;
        margin-top: -7%;
      }

      &__repeat {
        margin: -5% auto 0 auto;

        p {
        }
      }
    }

    .win {
      padding-bottom: 20px;
      min-height: calc((var(--vh, 1vh) * 100) - (var(--height_header)) - 20px);

      &__green-block {
        font-size: 16px;
        line-height: 120%;
        padding: 15px;
        height: 90px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      &__text {
        font-size: 16px;
        line-height: 120%;

        &.mob {
          display: block;
        }

        &.desk {
          display: none;
        }

        span {
        }
      }

      &__arrow-down {
      }

      &__img-circle {
        max-width: 360px;
        margin-top: 30px;
      }

      &__text-center {
        font-size: 16px;
        line-height: 120%;
        margin-bottom: 20px;
        margin-top: 20px;
      }

      &__info-text-btn {
        margin-bottom: 0;
      }

      &__info-text {
        p {
          font-size: 12px;
          line-height: 120%;

          sup {
          }
        }

        span {
        }
      }

      &__info-btn {
      }

      &__popup {
        padding: 18px 20px 26px 20px;
        bottom: 10px;
      }

      &__popup--close-btn {
        margin-bottom: 7px;

        svg {

        }
      }

      &__popup--text {
        font-size: 8px;
        line-height: 8px;
      }
    }

    .notes-wrapper {
      &.desk {
        display: none;
      }
    }
  }

}
</style>
